import { QueryClient } from "@tanstack/react-query"
import axios, { AxiosRequestConfig } from "axios"
import { ControllerResponse } from '../../../backend/factories/controller.factory.types'


export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,           // Prevent refetching when component mounts
      refetchOnWindowFocus: false,     // Disable refetching on window focus
      staleTime: 1000 * 60 * 5,        // Data stays fresh for 5 minutes
      cacheTime: 1000 * 60 * 5,        // Cache data for 10 minutes
      retry: 1,                        // Retry failed queries only once
    },
  },
})

interface Result<Data> extends ControllerResponse<Data> {
  result: Data,
  ok: number
}

export const api = axios.create({
  baseURL: process.env.GATSBY_OMD_API_URL || "http://localhost:3000",
})

export const fetch = <T = unknown>(url: string, params?: AxiosRequestConfig): Promise<T> => {
  return api.get<Result<T>>(url, params).then(res => res.data.result)
}