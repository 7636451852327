import { MembraneSrnfSchema as BackendSrnfMembraneSchema } from "../../../../../backend/models/membrane-srnf.model"
import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { buildMembranesQueryFilters, fetchMembrane, fetchMembranes, fetchMembranesSchema, Membrane, MembraneFilters, MembranePaths, MembraneSchema } from '../membranes-queries'
import { CommonPaths } from "../../omd"
import { cloneDeep } from "lodash"
import { MEMBRANES_SRNF_SOLUTES_NAME } from '../../../../../back-office/src/stores/membranes-srnf/data'
import { useOmdStore } from "../../../store/omd-store"
import { useEffect, useState } from "react"

export type SrnfMembrane = Membrane<BackendSrnfMembraneSchema>

export type SrnfMembranePaths = MembranePaths<SrnfMembrane>

export type SrnfMembraneAllowedPaths = CommonPaths<
  SrnfMembranePaths,
  'characterizationResults.characterizationData' |
  'characterizationResults.contactAngle' |
  'characterizationResults.totalThickness' |
  'filtrationResults.mwco' |
  'filtrationResults.permeance' |
  'filtrationResults.solventPermeance' |
  //'modification' |
  'supportLayerChemistry' |
  'testConditions.filtrationMode' |
  'testConditions.hydraulicP' |
  'testConditions.solvent1' |
  'testConditions.solvent2' |
  'testConditions.temperature' |
  "chemistry" |
  "report.publicationDate" |
  "structure" |
  "type" |
  'source' |
  'postTreatment' |
  'supportLayer.postTreatment'
>
  | 'soluteChoice.solute.molecularWeight'
  | 'soluteChoice.solute.category'
  | 'soluteChoice.solute.name'
  | 'filtrationResults.rejection'

// All filters available to the user for SRNF Membranes
export const srnfMembranesAllowedPaths: SrnfMembraneAllowedPaths[] =
  [
    'characterizationResults.characterizationData',
    'characterizationResults.contactAngle',
    'characterizationResults.totalThickness',
    'filtrationResults.mwco',
    'filtrationResults.permeance',
    'filtrationResults.rejection',
    'filtrationResults.solventPermeance',
    //'modification',
    'report.publicationDate',
    'soluteChoice.solute.category',
    'soluteChoice.solute.molecularWeight',
    'soluteChoice.solute.name',
    'supportLayerChemistry',
    'testConditions.filtrationMode',
    'testConditions.hydraulicP',
    'testConditions.solvent1',
    'testConditions.solvent2',
    'testConditions.temperature',
    'type',
    "chemistry",
    "structure",
    'postTreatment',
    'supportLayer.postTreatment'
  ] as const

export type SrnfMembranesFilters = MembraneFilters<SrnfMembrane, SrnfMembraneAllowedPaths> & { 'filter'?: string }

export type SrnfMembraneSchema = MembraneSchema<SrnfMembraneAllowedPaths | 'soluteChoice.solute.molecularWeight'>

export type SrnfMembranesQueryKey = 'srnf-membranes'
export const queryKey: SrnfMembranesQueryKey = 'srnf-membranes'
export const srnfMembranesQueryPath = '/membranes-srnf'
export type SrnfMembranesSchemaQueryKey = 'srnf-membranes-schema'
export const schemaQueryKey: SrnfMembranesSchemaQueryKey = 'srnf-membranes-schema'
export const srnfMembranesSchemaQueryPath = srnfMembranesQueryPath + '/schema'

export const fetchSrnfMembranes = (params?: SrnfMembranesFilters) => {
  const builtParams = buildSrnfMembranesQueryFilters(params)
  return fetchMembranes<SrnfMembrane, SrnfMembranesFilters>('/membranes-srnf', builtParams)
}

export const fetchSrnfMembranesSchema = async () => {
  const srnfMembranesSchema = await fetchMembranesSchema<SrnfMembraneSchema>('/membranes-srnf/schema')

  srnfMembranesSchema['filtrationResults.rejection'] = {
    // @ts-expect-error is not in Allowed Paths
    ...srnfMembranesSchema['filtrationResults.rejection1'],
    path: 'filtrationResults.rejection',
    labels: {
      // @ts-expect-error is not in Allowed Paths
      ...srnfMembranesSchema['filtrationResults.rejection1'].labels,
      short: 'Observed rejection',
      long: 'Observed rejection',
    },
  }

  srnfMembranesSchema['soluteChoice.solute.molecularWeight'] = {
    // @ts-expect-error is not in Allowed Paths
    ...srnfMembranesSchema['soluteChoice.solute1.molecularWeight'],
    path: 'soluteChoice.solute.molecularWeight',
    labels: {
      // @ts-expect-error is not in Allowed Paths
      ...srnfMembranesSchema['soluteChoice.solute1.molecularWeight'].labels,
      short: 'Solute MW',
      long: 'Molecular Weight of solute'
    },
    validations: {}
  }

  srnfMembranesSchema['soluteChoice.solute.category'] = {
    // @ts-expect-error is not in Allowed Paths
    ...srnfMembranesSchema['soluteChoice.solute1.category'],
    path: 'soluteChoice.solute.category',
    labels: {
      // @ts-expect-error is not in Allowed Paths
      ...srnfMembranesSchema['soluteChoice.solute1.category'].labels,
      short: 'Solute category',
      long: 'Category of solute'
    },
  }
  srnfMembranesSchema['soluteChoice.solute.name'] = {
    // @ts-expect-error is not in Allowed Paths
    ...srnfMembranesSchema['soluteChoice.solute1.name'],
    path: 'soluteChoice.solute.name',
    labels: {
      // @ts-expect-error is not in Allowed Paths
      ...srnfMembranesSchema['soluteChoice.solute1.name'].labels,
      short: 'Solute name',
      long: 'Name of solute'
    },
    validations: {
      enum: Object.values(MEMBRANES_SRNF_SOLUTES_NAME).flat(1).map(s => s.name)
    }
  }
  return srnfMembranesSchema
}

export const fetchSrnfMembrane = (id: BackendSrnfMembraneSchema['_id']) => {
  return fetchMembrane<SrnfMembrane>(`/membranes-srnf/${id}`)
}


export const useDebouncedQuery = <TQueryFnData = unknown, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'> & { debounce: number }
) => {
  const [newQueryKey, setNewQueryKey] = useState(queryKey);
  const stringify = (obj: object) => JSON.stringify(obj);
  const { debounce } = options

  useEffect(() => {
    if (stringify(queryKey) !== stringify(newQueryKey)) {
      const timerId = setTimeout(
        () => setNewQueryKey(queryKey),
        debounce
      );
      return () => clearTimeout(timerId);
    }
  }, [queryKey]);

  return useQuery(newQueryKey, queryFn, options);
}

export const useSrnfMembranesQuery = (params?: SrnfMembranesFilters) => useDebouncedQuery(
  [queryKey, params],
  () => fetchSrnfMembranes({ ...params/* , _id: '66042da9b6f18181f85ebca8' */ }),
  { debounce: 200, keepPreviousData: true }
)

export const useSrnfMembraneQuery = (id: BackendSrnfMembraneSchema['_id']) => useQuery(
  ['srnf-membrane', [id]],
  () => fetchSrnfMembrane(id),
)

export const buildSrnfMembranesQueryFilters = (params?: SrnfMembranesFilters) => {
  let newParams = cloneDeep(params)
  let filter = { $or: [] as { $and: object[] }[] }
  if (
    newParams && (
      'soluteChoice.solute.molecularWeight' in newParams ||
      'soluteChoice.solute.name' in newParams ||
      'soluteChoice.solute.category' in newParams ||
      'filtrationResults.rejection' in newParams
    ) && (
      newParams['soluteChoice.solute.molecularWeight'] ||
      newParams['soluteChoice.solute.name'] ||
      newParams['soluteChoice.solute.category'] ||
      newParams['filtrationResults.rejection']
    )
  ) {
    filter.$or = [...Array(15).keys()].map(i => ({ $and: [] }))
    if (newParams && 'soluteChoice.solute.molecularWeight' in newParams) {
      if (typeof newParams['soluteChoice.solute.molecularWeight'] === 'object') {
        if (newParams['soluteChoice.solute.molecularWeight']) {
          const min = 'min' in newParams['soluteChoice.solute.molecularWeight'] ? newParams['soluteChoice.solute.molecularWeight'].min : undefined
          const max = 'max' in newParams['soluteChoice.solute.molecularWeight'] ? newParams['soluteChoice.solute.molecularWeight'].max : undefined
          filter['$or'].map((and, i) => {
            and.$and.push({ [`soluteChoice.solute${i + 1}.molecularWeight`]: { $gte: min, $lte: max } })
            return and
          })
        }
      }
      delete newParams['soluteChoice.solute.molecularWeight']
    }
    if (newParams && 'filtrationResults.rejection' in newParams) {
      if (typeof newParams['filtrationResults.rejection'] === 'object') {
        if (newParams['filtrationResults.rejection']) {
          const min = 'min' in newParams['filtrationResults.rejection'] ? newParams['filtrationResults.rejection'].min : undefined
          const max = 'max' in newParams['filtrationResults.rejection'] ? newParams['filtrationResults.rejection'].max : undefined
          filter['$or'].map((and, i) => {
            and.$and.push({ [`filtrationResults.rejection${i + 1}`]: { $gte: min, $lte: max } })
            return and
          })
        }
      }
      delete newParams['filtrationResults.rejection']
    }
    if (newParams && 'soluteChoice.solute.category' in newParams) {
      if (Array.isArray(newParams['soluteChoice.solute.category']) && newParams['soluteChoice.solute.category'].length > 0) {
        filter['$or'].map((and, i) => {
          and.$and.push({ [`soluteChoice.solute${i + 1}.category`]: newParams!['soluteChoice.solute.category'] })
        })
      }
      delete newParams['soluteChoice.solute.category']
    }
    if (newParams && 'soluteChoice.solute.name' in newParams) {
      if (Array.isArray(newParams['soluteChoice.solute.name']) && newParams['soluteChoice.solute.name'].length > 0) {
        filter['$or'].map((and, i) => {
          and.$and.push({ [`soluteChoice.solute${i + 1}.name`]: newParams!['soluteChoice.solute.name'] })
        })
      }
      delete newParams['soluteChoice.solute.name']
    }
  }
  if (filter.$or.length > 0) {
    if (newParams === undefined) newParams = {}
    newParams['filter'] = JSON.stringify(filter)
  }
  return newParams
}

export const getExportSrnfCsvUrl = () => {
  const filters = structuredClone(useOmdStore.getState().srnf.datasetFilters)
  const highlighted = useOmdStore.getState().srnf.highlightedItems
  const omdIds = highlighted.map(item => item.omdId)
  if (omdIds.length > 0) {
    //@ts-expect-error
    filters['omdId'] = omdIds
  }
  const builtSrnf = buildSrnfMembranesQueryFilters(filters)
  const builtMembrane = buildMembranesQueryFilters(builtSrnf)
  const url = new URL(`${process.env.GATSBY_OMD_API_URL || "http://localhost:3000"}/membranes-srnf/export`)
  const searchParams = new URLSearchParams(builtMembrane)
  url.search = searchParams.toString()
  return url.toString()
}